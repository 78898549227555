import React from "react"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImgSlider from "../components/slider"
import "../../node_modules/slick-carousel/slick/slick.css"; 
import "../../node_modules/slick-carousel/slick/slick-theme.css";

import subunitsStyles from "../styles/pages/subunits.module.css"

import { IconContext } from "react-icons"
import { FaPhoneAlt, FaGlobe } from "react-icons/fa"

const SubUnitsPage = ({ data }) => (
  <Layout location="subunits">
    <SEO title="SubUnits" />
    <div className={ subunitsStyles.header }>
      <div className={ subunitsStyles.headerImage }></div>
      <div className={ subunitsStyles.headerText }>
        <h4 className={ subunitsStyles.title }>SubUnits</h4>
        <p className={ subunitsStyles.subtitle }>Get to know more about the company and all its parts.</p>
      </div>
    </div>
    <div className={ subunitsStyles.container }>
      <div className={ subunitsStyles.sectionTitle }>
        <div className={ subunitsStyles.icon }><Img fluid={data.icon1.childImageSharp.fluid} /></div>
        <h3>Showcase</h3>
      </div>
      <ImgSlider />
    </div>
    <div className={ subunitsStyles.descriptions }>
      <div className={ subunitsStyles.sectionTitle }>
        <div className={ subunitsStyles.icon }><Img fluid={data.icon2.childImageSharp.fluid} /></div>
        <h3>SubUnits</h3>
      </div>
      <div className={ subunitsStyles.teamED }>
        <a href="http://tedevents.com.ph/" className={ subunitsStyles.imgContainer }>
          <Img fluid={ data.image1.childImageSharp.fluid } />
        </a>
        <hr />
        <div className={ subunitsStyles.description }>
          <p>A certified professional events management unit that provides excellent and dynamic events services mainly to corporate and association accounts. It is a bona fide member of ASAP (Advertising Services Association of the Phillippines) - the official umbrella association of production & technical suppliers in the advertising & marketing communication industry of the country.</p>
          <div className={ subunitsStyles.detail }>
            <IconContext.Provider value={{ className: subunitsStyles.miniIcon }}>
              <FaPhoneAlt />
            </IconContext.Provider>
            <h5>3413-6436</h5>
          </div>
          <div className={ subunitsStyles.detail }>
            <IconContext.Provider value={{ className: subunitsStyles.miniIcon }}>
              <FaGlobe />
            </IconContext.Provider>
            <a href="http://tedevents.com.ph/">tedevents.com.ph</a>
          </div>
        </div>
      </div>
      <div className={ subunitsStyles.teamplay }>
        <a href="http://teamplay.com.ph/" className={ subunitsStyles.imgContainer }>
          <Img fluid={ data.image2.childImageSharp.fluid } />
        </a>
        <hr />
        <div className={ subunitsStyles.description }>
          <p>T.E.A.M.P.L.A.Y. (Training, Empowerment, Assembly and Management of Personnel for Long-term Activation Yield) a promotional manpower sourcing and deployment unit for various field activations of projects and events. Its staff development program benefits from being a member of PMAP, PSTD, and PCAEE.</p>
          <div className={ subunitsStyles.detail }>
            <IconContext.Provider value={{ className: subunitsStyles.miniIcon }}>
              <FaPhoneAlt />
            </IconContext.Provider>
            <h5>3448-5386</h5>
          </div>
          <div className={ subunitsStyles.detail }>
            <IconContext.Provider value={{ className: subunitsStyles.miniIcon }}>
              <FaGlobe />
            </IconContext.Provider>
            <a href="http://teamplay.com.ph/">teamplay.com.ph</a>
          </div>
        </div>
      </div>
      <div className={ subunitsStyles.trueNorth }>
        <a href="http://truenortharthouse.com.ph/" className={ subunitsStyles.imgContainer }>
          <Img fluid={ data.image3.childImageSharp.fluid } />
        </a>
        <hr />
        <div className={ subunitsStyles.description }>
          <p>A graphic arts, design and video studio for creative requirements. It is heavy in stage and staging designs, exhibit booths, logos, communication collaterals, audio-visual presentations and other creative needs.</p>
          <div className={ subunitsStyles.detail }>
            <IconContext.Provider value={{ className: subunitsStyles.miniIcon }}>
              <FaPhoneAlt />
            </IconContext.Provider>
            <h5>8426-4844</h5>
          </div>
          <div className={ subunitsStyles.detail }>
            <IconContext.Provider value={{ className: subunitsStyles.miniIcon }}>
              <FaGlobe />
            </IconContext.Provider>
            <a href="http://truenortharthouse.com.ph/">truenortharthouse.com.ph</a>
          </div>
        </div>
      </div>
      <div className={ subunitsStyles.cc }>
        <a href="http://creativecaboose.com.ph/" className={ subunitsStyles.imgContainer }>
          <Img fluid={ data.image4.childImageSharp.fluid } />
        </a>
        <hr />
        <div className={ subunitsStyles.description }>
          <p>Post-production and related services for video and digital film projects. With this set-up, the execution of projects is delivered in a swift, cost-efficient and effective manner.</p>
          <div className={ subunitsStyles.detail }>
            <IconContext.Provider value={{ className: subunitsStyles.miniIcon }}>
              <FaPhoneAlt />
            </IconContext.Provider>
            <h5>8426-4844</h5>
          </div>
          <div className={ subunitsStyles.detail }>
            <IconContext.Provider value={{ className: subunitsStyles.miniIcon }}>
              <FaGlobe />
            </IconContext.Provider>
            <a href="http://creativecaboose.com.ph/">creativecaboose.com.ph</a>
          </div>
        </div>
      </div>
      <div className={ subunitsStyles.flair }>
        <a href="http://flair.com.ph/" className={ subunitsStyles.imgContainer }>
          <Img fluid={ data.image5.childImageSharp.fluid } />
        </a>
        <hr />
        <div className={ subunitsStyles.description }>
          <p>A talent hub supplying talents such as models, resource speakers, voice-over talents, emcees and other performers of special talents & skills, for events, commercial and film jobs.</p>
          <div className={ subunitsStyles.detail }>
            <IconContext.Provider value={{ className: subunitsStyles.miniIcon }}>
              <FaPhoneAlt />
            </IconContext.Provider>
            <h5>3448-5386</h5>
          </div>
          <div className={ subunitsStyles.detail }>
            <IconContext.Provider value={{ className: subunitsStyles.miniIcon }}>
              <FaGlobe />
            </IconContext.Provider>
            <a href="http://flair.com.ph/">flair.com.ph</a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export const companyLogo = graphql`
  fragment companyLogo on File {
    childImageSharp {
      fluid(maxWidth: 400) {
        aspectRatio
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const icon = graphql`
  fragment icon on File {
    childImageSharp {
      fluid(maxWidth: 200) {
        aspectRatio
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    icon1: file(relativePath: { eq: "showcase.png" }) {
      ...icon
    }
    icon2: file(relativePath: { eq: "subunits.png" }) {
      ...icon
    }
    image1: file(relativePath: { eq: "teamed.png" }) {
      ...companyLogo
    }
    image2: file(relativePath: { eq: "Teamplay.png" }) {
      ...companyLogo
    }
    image3: file(relativePath: { eq: "TrueNorth-ArtHouse.png" }) {
      ...companyLogo
    }
    image4: file(relativePath: { eq: "creative-caboose.png" }) {
      ...companyLogo
    }
    image5: file(relativePath: { eq: "Flair.png" }) {
      ...companyLogo
    }
  }
`

export default SubUnitsPage
import React from "react"
import Slider from "react-slick";

import slide1 from "../images/Artboard-1.png"
import slide2 from "../images/Artboard-2.png"
import slide3 from "../images/Artboard-3.png"
import slide4 from "../images/Artboard-4.png"
import slide5 from "../images/Artboard-5.png"
import slide6 from "../images/Artboard-6.png"
import slide7 from "../images/Artboard-7.png"
import slide8 from "../images/Artboard-8.png"
import slide9 from "../images/Artboard-9.png"
import slide10 from "../images/Artboard-10.png"
import slide11 from "../images/Artboard-11.png"
import slide12 from "../images/Artboard-12.png"
import slide13 from "../images/Artboard-13.png"
import slide14 from "../images/Artboard-14.png"
import slide15 from "../images/Artboard-15.png"

import subunitsStyles from "../styles/pages/subunits.module.css"

const ImgSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 10000,
        fade: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                }
            }
        ]
    }
    return (
        <div className={ subunitsStyles.sliderContainer }>
            <Slider {...settings}>
                <div><img src={ slide1 } alt=""/></div>
                <div><img src={ slide2 } alt=""/></div>
                <div><img src={ slide3 } alt=""/></div>
                <div><img src={ slide4 } alt=""/></div>
                <div><img src={ slide5 } alt=""/></div>
                <div><img src={ slide6 } alt=""/></div>
                <div><img src={ slide7 } alt=""/></div>
                <div><img src={ slide8 } alt=""/></div>
                <div><img src={ slide9 } alt=""/></div>
                <div><img src={ slide10 } alt=""/></div>
                <div><img src={ slide11 } alt=""/></div>
                <div><img src={ slide12 } alt=""/></div>
                <div><img src={ slide13 } alt=""/></div>
                <div><img src={ slide14 } alt=""/></div>
                <div><img src={ slide15 } alt=""/></div>
            </Slider>
        </div>
    );
}

export default ImgSlider